<template>
  <div class="section-main-100 pa-9">
    <!-- Заголовок -->
    <h1 class="weight-900 text-h4 mb-6 text-uppercase">О нас</h1>

    <!-- Изображение -->
    <img
      alt="О компании"
      :src="about"
      class="image"
    />

    <!-- Описание компании -->
    <p class="description mt-6">
      Наше агентство создано в <span class="blue--text">2014</span> году коллективом опытных агентов, на тот момент
      работающих в недвижимости более 10 лет. <br>
      Основное отличие нашего агентства – это то, что большинство наших клиентов приходят к нам по рекомендации. <br>
      Для удобства наших клиентов мы создали сайт, на котором размещены только реальные
      квартиры,<span class="blue--text"> без фейков и рекламы</span>.
      <br>
      Благодаря разработанной нами CRM-системе, наши клиенты получают информацию об актуальных вариантах по их запросу
      <span class="blue--text"> в течении часа</span> с момента обращения к нам.
    </p>

    <!-- Блок с информацией о предпринимателе -->
    <div class="company-info mt-6">
      <h2 class="weight-700 text-h6 mb-4">Юридическая информация</h2>
      <p><strong>ИП Меркурьева Мария Викторовна</strong></p>
      <p><strong>Телефон:</strong> <a href="tel:+79882574445">+7 (988) 257-44-45</a></p>
      <p><strong>ОГРНИП:</strong> 317619600101031</p>
      <p><strong>ИНН:</strong> 616304742897</p>
      <p><strong>Юридический адрес:</strong> г Ростов на Дону, ул 2-я Воробьиная, 28</p>
    </div>
  </div>
</template>

<script>
import about from '@/assets/about.jpeg'

export default {
  name: 'AboutUs',
  data () {
    return {
      about
    }
  }
}
</script>

<style scoped>
  .image {
    width: 100%;
    height: 323px;
    object-fit: cover;
    object-position: center top;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .description {
    max-width: 50%;
    line-height: 1.6;
    font-size: 1rem;
  }
  .blue--text {
    color: #1976d2;
    font-weight: bold;
    text-decoration: underline;
  }
  .company-info {
    max-width: 60%;
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  .company-info p {
    margin: 1rem 0;
  }
  @media (max-width: 800px) {
    .description {
      max-width: 90%;
      font-size: 0.9rem;
    }
    .image {
      height: 250px;
    }
    .company-info {
      max-width: 100%;
    }
    h1 {
      font-size: 1.8rem;
    }
    .company-info p {
      margin: 0.75rem 0;
    }
  }
  @media (max-width: 600px) {
    .description {
      font-size: 0.85rem;
    }
  }
</style>
